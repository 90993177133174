import React, { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { MuiOtpInput } from 'mui-one-time-password-input';
import CheckIcon from '@mui/icons-material/Check';
import { isAndroid, isDesktop, isIOS } from 'react-device-detect';
import { OTPModeProps } from '../types';
import { ELoginMethods, EOTPTextModel } from '../../../constants/enums';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '@appcharge/shared-ui';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';

const REDIRECT_MESSAGE_TIMEOUT = 10000;

export const OtpMode: React.FC<OTPModeProps> = ({ otpTokenKey }) => {
  const { publisherMetaData, API, isWebview } = useLoginPage();
  const { loginResponse, proofKey } = useLoginState();
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const [isRedirected, setIsRedirected] = useState(false);
  const [otpConfigResponse, setConfigOtpResponse] = useState<any>();
  const [isLoadingOtpConfig, setIsLoadingOtpConfig] = useState(true);
  const [otpConfigError, setOtpConfigError] = useState();
  const [isOTPButtonClicked, setIsOTPButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const numOfDigits = useMemo(
    () =>
      publisherMetaData.integration.playersAuthentication.otpTextModel === EOTPTextModel.SIX_DIGITS
        ? 6
        : 4,
    [publisherMetaData.integration.playersAuthentication]
  );

  const otpButton = useMemo(
    () => publisherMetaData.storeTheme.login.otpButton,
    [publisherMetaData.storeTheme.login.otpButton]
  );

  useEffect(() => {
    setIsLoadingOtpConfig(true);
    if (!proofKey) {
      API.getOTP?.()
        .then((response) => {
          setConfigOtpResponse(response);
          setIsLoadingOtpConfig(false);
        })
        .catch((e) => {
          setOtpConfigError(e);
        });
    }
  }, []);

  const login = (otpValue: string, accessToken?: string) => {
    loginResponse({
      authMethod: ELoginMethods.OTP,
      otp: {
        playerCode: otpValue,
        accessToken:
          accessToken || Cookies.get('otp_accessToken') || otpConfigResponse?.data?.accessToken,
      },
    });
  };

  useEffect(() => {
    if (otpConfigResponse?.data?.accessToken) {
      const now = new Date();
      Cookies.set('otp_accessToken', otpConfigResponse?.data?.accessToken, {
        expires: now.setMinutes(now.getMinutes() + 5),
      });
    }
  }, [otpConfigResponse?.data]);

  if (proofKey) {
    if (otpTokenKey) {
      login(proofKey, otpTokenKey);
    } else {
      login(proofKey);
    }
    return null;
  }

  const handleChange = (newValue: string) => {
    setOtp(newValue);
    if (newValue.length >= numOfDigits!) handleComplete(newValue);
  };

  const handleComplete = async (otpValue: string) => {
    login(otpValue);
    (document.activeElement as HTMLElement).blur();
  };

  const handleAuthenticateMeBtnClick = async () => {
    setIsLoading(true);
    window.location.replace(otpConfigResponse.data.deepLink);
    setIsOTPButtonClicked(true);
    setTimeout(() => {
      setIsRedirected(true);
      setIsLoading(false);
    }, REDIRECT_MESSAGE_TIMEOUT);
  };

  if (otpConfigError) {
    window.location.replace('/failed');
  }

  if (isLoadingOtpConfig) {
    return (
      <Stack pb={4} width="100%" justifyContent="center" alignItems="center">
        <CircularProgress
          sx={{
            color: 'white',
          }}
        />
      </Stack>
    );
  }

  return (
    <>
      {isDesktop && (!isIOS || !isAndroid) && (
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
          <Box className="otp-container" gap={2} p={2}>
            <Typography data-testid="otp-qr-message">{t('login.otp.instructions')}</Typography>
            <Box className="qr">
              <QRCode
                size={80}
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  width: '100%',
                }}
                value={otpConfigResponse.data.deepLink || ''}
                viewBox={`0 0 80 80`}
                data-testid="otp-qr-code"
              />
            </Box>
          </Box>
          <h2>{t('login.otp.enterCode')}</h2>
          <MuiOtpInput value={otp} onChange={handleChange} length={numOfDigits} autoFocus={true} />
        </Box>
      )}
      {!isLoading && isRedirected && (
        <Box
          className="otp-container"
          flexDirection="column"
          gap={2}
          p={isWebview ? 0 : 2}
          style={{ width: '100%' }}
        >
          <CheckIcon fontSize="large" />
          <Typography textAlign="center">
            {t('login.otp.redirected')}
            <br />
            {t('login.otp.closeTab')}
          </Typography>
        </Box>
      )}
      {!(isDesktop || isOTPButtonClicked) && (isIOS || isAndroid) && (
        <Box
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          p={isWebview ? 0 : 2}
          width={'100%'}
        >
          <ActionButton
            text={t('login.otp.authenticateMeNow')}
            action={handleAuthenticateMeBtnClick}
            colors={otpButton.backgroundColor}
            textColor={otpButton.textColor.colorOne}
            icon={otpButton.icon}
            borderRadius="8px"
            dataTestId="otp-authenticate-button"
            width={'100%'}
          />
        </Box>
      )}
    </>
  );
};
