import { useQuery, useMutation, useQueryClient } from 'react-query';
import { APIProps, BootResponse, LoginResponse } from '../constants/apiResponses.types';
import {
  ELocalStorageKeys,
  ESupportType,
  EQueryKeys,
  EEventsType,
  EStorePhase,
  EDeviceType,
} from '../constants/enums';
import useAxios from './useAxios';
import { localStorageUtil } from 'state/localStorage.state.service';
import { SupportData } from '../constants/support.types';
import useCustomEvents from './useCustomEvents';
import { isDesktop } from 'react-device-detect';
import {
  CheckoutOrderPayload,
  CreateCheckoutSessionPayload,
  CreateOrderPayload,
  PublisherWebhookEvent,
  SendOrderImpressionPayload,
} from '../constants/apiRequest.types';
import useFEMonitoring from './useFEMonitoring';
import { CACHE_TIME_MS, STALE_TIME_MS } from '../constants/defaults';
import { LoginRequestData } from '../pages/login/types';
import { AxiosError } from 'axios';

export default function useApi({ orderId, platform }: APIProps) {
  const axios = useAxios();
  const customEvents = useCustomEvents();
  const { logToNewRelic } = useFEMonitoring();
  const queryClient = useQueryClient();
  const initialLanguage = localStorageUtil.get(ELocalStorageKeys.CURRENT_LANGUAGE);

  const getOffers = useQuery({
    queryKey: EQueryKeys.OFFERS,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      return localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE)
        ? JSON.parse(localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE))
        : await axios
            .get(`/store/offers?${initialLanguage ? `language=${initialLanguage}` : ''}`)
            .catch((err) => {
              customEvents.sendCustomEvent(
                EEventsType.ERROR,
                {
                  type: 'api error',
                  route: 'getOffers',
                  error: JSON.stringify(err),
                },
                EStorePhase.POST_LOGIN
              );
              logToNewRelic(err);
              throw err;
            });
    },
  });

  const getOffersV2 = useQuery({
    queryKey: EQueryKeys.OFFERS,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      return localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE)
        ? JSON.parse(localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE))
        : await axios
            .get(`/store/offers/v2?${initialLanguage ? `language=${initialLanguage}` : ''}`)
            .catch((err) => {
              customEvents.sendCustomEvent(
                EEventsType.ERROR,
                {
                  type: 'api error',
                  route: 'getOffers',
                  error: JSON.stringify(err),
                },
                EStorePhase.POST_LOGIN
              );
              throw err;
            });
    },
  });

  const getPublisherMeta = useQuery<BootResponse, Error>({
    queryKey: EQueryKeys.BOOT,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      const response = await axios
        .get(
          `store/${
            localStorageUtil.get(ELocalStorageKeys.OVERRIDE_STORE) || window.location.host
          }/boot?${initialLanguage ? `language=${initialLanguage}` : ''}`,
          true
        )
        .catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getPublisherMeta',
              error: JSON.stringify(err),
            },
            EStorePhase.PRE_LOGIN
          );
          logToNewRelic(err);
          throw err;
        });
      localStorageUtil.set(ELocalStorageKeys.PUBLISHER_META, response);
      return localStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE)
        ? JSON.parse(localStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE))
        : response;
    },
  });

  const getOTP = () => {
    if (localStorageUtil.get(ELocalStorageKeys.OTP_API_OVERRIDE)) {
      return new Promise((resolve) => {
        resolve({
          data: JSON.parse(localStorageUtil.get(ELocalStorageKeys.OTP_API_OVERRIDE)),
        });
      });
    }
    return axios
      .post(
        `/store/otp/deeplink`,
        {
          data: {
            device: isDesktop ? EDeviceType.DESKTOP : 'APPCHARGE',
            publisherId: localStorageUtil.get(ELocalStorageKeys.PUBLISHER_META).storeTheme
              .publisherId,
            platform: platform?.toUpperCase(),
          },
          headers: {},
        },
        true
      )
      .catch((err) => {
        return logToNewRelic(err)
          .then(() => {
            return customEvents.sendCustomEvent(
              EEventsType.ERROR,
              {
                type: 'api error',
                route: 'getOTP',
                error: JSON.stringify(err),
              },
              EStorePhase.PRE_LOGIN
            );
          })
          .finally(() => {
            throw err;
          });
      });
  };

  const getPostLoginData = useQuery({
    queryKey: EQueryKeys.POST_LOGIN,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      return localStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE)
        ? JSON.parse(localStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE))
        : await axios.get('/store/postLogin').catch((err) => {
            customEvents.sendCustomEvent(
              EEventsType.ERROR,
              {
                type: 'api error',
                route: 'getPostLoginData',
                error: JSON.stringify(err),
              },
              EStorePhase.POST_LOGIN
            );
            logToNewRelic(err);
            throw err;
          });
    },
  });

  const getOrder = useQuery({
    queryKey: `${EQueryKeys.ORDER}_${orderId}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: async () => {
      return await axios.get(`store/orders/${orderId}`).catch((err) => {
        customEvents.sendCustomEvent(
          EEventsType.ERROR,
          {
            type: 'api error',
            route: 'getOrder',
            error: JSON.stringify(err),
          },
          EStorePhase.POST_LOGIN
        );
        logToNewRelic(err);
        throw err;
      });
    },
  });

  const getProductsAssets = (arrProductIds: string[]) => {
    const idsUrlParam = encodeURIComponent(arrProductIds.join(','));
    const queryKey = `${EQueryKeys.PRODUCTS}_${idsUrlParam}`;
    return queryClient.fetchQuery(
      queryKey,
      async () => {
        if (!arrProductIds || !arrProductIds.length) return [];
        return axios.get(`store/products/external-ids/${idsUrlParam}`).catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getProducts',
              error: JSON.stringify(err),
            },
            EStorePhase.OTHER
          );
          logToNewRelic(err);
          throw err;
        });
      },
      { cacheTime: 60000, staleTime: 60000 }
    );
  };

  const createCheckoutSessionV2 = useMutation(async (data: CreateCheckoutSessionPayload) => {
    return await axios.post(`/store/orders/checkout-session/v2?language=${initialLanguage}`, {
      data,
      headers: {},
    });
  }, {});

  const createOrder = useMutation(
    async (data: Partial<CreateOrderPayload>): Promise<any> => {
      return await axios.post(`/store/orders?language=${initialLanguage}`, {
        data,
        headers: {},
      });
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err.response?.data);
      },
    }
  );

  const createOrderV2 = useMutation(async (data: Partial<CreateOrderPayload>): Promise<any> => {
    return await axios.post(`/store/orders/v2?language=${initialLanguage}`, {
      data,
      headers: {},
    });
  }, {});

  const checkoutOrder = useMutation(
    async (data: CheckoutOrderPayload) => {
      return await axios.post(`/store/orders/checkout`, {
        data,
        headers: {},
      });
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err.response?.data);
      },
    }
  );

  const getStoreCampaign = useMutation(
    async (data: { tokenId: string; linkId?: string; orgId?: string }) => {
      const params = new URLSearchParams();
      if (data.linkId) params.append('linkId', data.linkId);
      if (data.orgId) params.append('orgId', data.orgId);
      const queryString = params.toString();
      return await axios.get(
        `/store/campaign/${data.tokenId}${queryString ? `?${queryString}` : ''}`
      );
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err.response?.data);
      },
    }
  );

  const login = useMutation(
    async (data: Partial<LoginRequestData>) => {
      return await axios.post<LoginResponse>('/store/auth/login', { data, headers: {} }, true);
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err.response?.data);
      },
    }
  );

  const logout = useMutation(
    async () => {
      return await axios.post(
        '/store/auth/logout',
        {
          data: {},
          headers: {},
        },
        false
      );
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err.response?.data);
      },
    }
  );

  const keepAlive = useMutation({
    retry: false,
    mutationFn: async () => {
      return await axios.post('/store/auth/keepalive', {
        data: {},
        headers: {},
      });
    },
  });

  const sendSupport = useMutation(
    async ({ data, supportType }: { data: SupportData; supportType: ESupportType }) => {
      const url =
        supportType === ESupportType.POST_LOGIN
          ? 'store/support/postlogin'
          : 'store/support/prelogin';
      return await axios.post(url, {
        data: data,
        headers: {},
      });
    },
    {
      onError: async (err: AxiosError) => {
        await logToNewRelic(err.response?.data);
      },
    }
  );

  const sendEmptySupport = (
    publisherId: string,
    supportPhase: ESupportType,
    storePhase: EStorePhase,
    orderId?: string
  ) => {
    sendSupport.mutateAsync(
      {
        data: {
          publisherId: publisherId,
          supportFullName: '',
          communicationType: '',
          communicationDetails: '',
          supportRequestReason: '',
          playerStorePhase: storePhase,
          playerMessage: '',
          orderId: orderId || '',
        },
        supportType: supportPhase,
      },
      {
        onError: async (err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'sendSupport',
              error: JSON.stringify(err),
            },
            storePhase
          );
          await logToNewRelic(err.response?.data);
        },
      }
    );
  };

  const sendPublisherWebhookEvent = useMutation(async (event: PublisherWebhookEvent) => {
    const integration = getPublisherMeta.data?.integration;
    if (
      !integration ||
      !integration.eventsWebhookUrl ||
      !integration.enabledWebhookEvents.includes(event.data.eventName)
    )
      return;
    return await axios
      .post(`/store/events/${event.eventType}`, {
        data: event.data,
        headers: {},
      })
      .catch((err) => {
        return logToNewRelic(err).then(() => {
          console.error(err);
        });
      });
  }, {});

  const sendShopLand = useMutation(async () => {
    return await axios
      .post('/store/analytics/shop-land', {
        data: {},
        headers: {},
      })
      .catch((err) => {
        return logToNewRelic(err).then(() => {
          console.error(err);
        });
      });
  }, {});

  const sendOrderImpression = (data: SendOrderImpressionPayload) => {
    const url = 'store/event';
    return axios
      .post(url, {
        data: {
          eventName: 'impression',
          ...data,
        },
        headers: {},
      })
      .catch((err) => {
        return logToNewRelic(err).then(() => {
          console.error(err);
        });
      });
  };

  const getLanguagesList = (publisherId: string) => {
    return queryClient.fetchQuery(
      `${EQueryKeys.LANGUAGES}`,
      async () => {
        return axios.get(`store/translations/${publisherId}/languages`, true).catch((err) => {
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'getLanguagesList',
              error: JSON.stringify(err),
            },
            EStorePhase.OTHER
          );
          logToNewRelic(err);
          throw err;
        });
      },
      { cacheTime: CACHE_TIME_MS, staleTime: STALE_TIME_MS }
    );
  };

  const getCurrentTranslations = (publisherId: string, language: string) => {
    return queryClient.fetchQuery(
      `${EQueryKeys.TRANSLATIONS}`,
      async () => {
        return axios
          .get(`store/translations/${publisherId}/languages/${language}`, true)
          .catch((err) => {
            customEvents.sendCustomEvent(
              EEventsType.ERROR,
              {
                type: 'api error',
                route: 'getCurrentTranslations',
                error: JSON.stringify(err),
              },
              EStorePhase.OTHER
            );
            logToNewRelic(err);
            throw err;
          });
      },
      { cacheTime: CACHE_TIME_MS, staleTime: STALE_TIME_MS }
    );
  };

  return {
    login,
    logout,
    sendShopLand,
    getProductsAssets,
    getOrder,
    getOffers,
    getOffersV2,
    getStoreCampaign,
    keepAlive,
    sendSupport,
    createOrder,
    createOrderV2,
    checkoutOrder,
    createCheckoutSessionV2,
    getOTP,
    getPublisherMeta,
    getPostLoginData,
    sendEmptySupport,
    sendPublisherWebhookEvent,
    sendOrderImpression,
    getLanguagesList,
    getCurrentTranslations,
  };
}
