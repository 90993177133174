import useApi from '../../hooks/useApi';
import { Button, Modal, Box, Typography, IconButton } from '@mui/material';
import { BootResponse } from '../../constants/apiResponses.types';
import { EStorePhase, EEventsType } from '../../constants/enums';
import { LogoutModalProps } from './LogoutModal.types';
import useCustomEvents from '../../hooks/useCustomEvents';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const LogoutModal = ({ closePopup, logout, playerName, showLogoutModal }: LogoutModalProps) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();

  const { t } = useTranslation();

  const logoutHandler = () => {
    closePopup();
    customEvents.sendCustomEvent(
      EEventsType.LOGOUT,
      {
        reason: 'logout clicked',
      },
      EStorePhase.POST_LOGIN
    );
    logout();
  };

  return (
    <Modal
      open={showLogoutModal}
      onClose={closePopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            transition: 'opacity 0.6s ease',
          },
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          color: '#FFF',
          fontFamily: publisherMetaData.storeTheme.general.font,
          width: '100%',
          padding: '0px 16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box width="320px" textAlign="right">
          <IconButton onClick={closePopup} size="small">
            <CloseIcon style={{ fontSize: 40, color: 'white' }} />
          </IconButton>
        </Box>
        <Box marginBottom="15px" textAlign="center">
          <Typography
            variant="h3"
            component="h2"
            marginBottom={1.5}
            fontWeight={700}
            fontFamily="inherit"
            sx={{
              color: 'inherit',
            }}
          >
            {t('logoutModal.logout')}
          </Typography>
          <Typography
            component="p"
            fontWeight={400}
            fontSize="14px"
            fontFamily="inherit"
            sx={{
              color: 'inherit',
            }}
          >
            {`${playerName ? `${playerName}, ` : ''}${t('logoutModal.disconnectConfirmation')}`}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            width: '240px',
            height: '48px',
            padding: '14px 74px',
            borderRadius: '7px',
            borderColor: '#FFF',
            color: 'inherit',
            textTransform: 'none',
            fontFamily: 'inherit',
            '&:hover': {
              borderColor: 'inherit',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'inherit',
            },
          }}
          onClick={logoutHandler}
        >
          {t('logoutModal.logout')}
        </Button>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
