import useApi from '../../hooks/useApi';
import './style.scss';
import { BootResponse, LoginResponse } from '../../constants/apiResponses.types';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import {
  AcCircularLoader,
  Background,
  Overlay,
  useIsDesktop,
  useIsLandscape,
} from '@appcharge/shared-ui';
import CookieConsent from '../../components/cookie-consent/cookie-consent';
import useUrlQuery from '../../hooks/useUrlQuery';
import useCustomEvents from '../../hooks/useCustomEvents';
import { localStorageUtil } from 'state/localStorage.state.service';
import {
  EBundlesInternalViewModel,
  EEventsType,
  ELocalStorageKeys,
  EQueryParams,
  ESessionStorageKeys,
  EStorePhase,
  ELoginMethods,
  ELoginMode,
  ELoginEvents,
} from '../../constants/enums';
import InfoModal from '../../components/InfoModal/InfoModal';
import { Stack } from '@mui/material';
import { browserName, isMobile, osName } from 'react-device-detect';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import { LoginProps } from './types';
import { loginPageService } from './login-page.service';
import { Login } from './Components/Login';
import useLoginEvents from '../../hooks/useLoginEvents';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { useLoginState } from 'state/hooks/login.state.hook';

const ENV = process.env.REACT_APP_ENV;
const DYNAMO_PLAYER_QUERY_PARAM = 'd10x_cuid';

export const LoginPage: React.FC<LoginProps> = ({ initAnalytics }) => {
  const API = useApi({
    platform: isMobile ? osName : browserName,
  });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();
  const utmSource = useUrlQuery(EQueryParams.UTM_SOURCE);
  const [showCookieOverlay, setShowCookieOverlay] = useState(
    ENV !== 'dev' && localStorageUtil.get(ELocalStorageKeys.ANALYTICS) === null
  );
  const [isProductMadness, setIsProductMadness] = useState(false);
  const [otpProductMadness, setIsOtpProductMadness] = useState(false);
  const [loginBgColor, setLoginBgColor] = useState('');
  const navigate = useNavigate();
  const [otpToken, setOtpToken] = useState('');
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { t } = useTranslation();
  const isCampaignPath = location.pathname.includes('/campaign/');

  const urlParams = new URLSearchParams(window.location.search);

  const supportMultiLanguageEnabled = publisherMetaData.featureFlags.store_multi_language_support;
  const { languagesList } = useLocalizationState();

  const {
    processing,
    proofKey,
    loggingWithProofKey,
    showInfoModal,
    iframeUrl,
    setProcessing,
    setProofKey,
    closeInfoModal,
    clear,
  } = useLoginState();
  const shouldDisplayLanguagePicker =
    supportMultiLanguageEnabled && languagesList && languagesList?.length > 1;

  useEffect(() => {
    const onLoginLand = async () => {
      if (localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) && !isCampaignPath) {
        navigate(`/shop${utmSource ? `?utm_source=${utmSource}` : ''}`);
      } else {
        sendPublisherWebhookLoginEvent(ELoginEvents.LOGIN_LAND);
        const eventType: EEventsType = loginPageService.isWebviewBrowser()
          ? EEventsType.WEBVIEW_LAND
          : EEventsType.LOGIN_LAND;
        customEvents.sendCustomEvent(
          eventType,
          loginPageService.getLoginEventsData({
            eventName: EEventsType.LOGIN_LAND,
            gameName: publisherMetaData.publisher?.storeTabName,
          }),
          EStorePhase.PRE_LOGIN
        );
        if (localStorageUtil.get(ELocalStorageKeys.PUBLISHER_META) === null) {
          await API.getPublisherMeta.refetch();
        }
      }
    };
    onLoginLand();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isCampaignPath) {
      const path = location.pathname;
      const token = path?.split('/')?.pop() || '';
      const queryParams = new URLSearchParams(location.search);
      const playerId = queryParams.get(DYNAMO_PLAYER_QUERY_PARAM);
      handleLoginFromCampaign({
        publisherId: publisherMetaData.storeTheme.publisherId,
        playerId: playerId || '',
        token,
      });
    }
  }, [isCampaignPath]);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) sessionStorage.setItem('source', 'home screen bookmark');
  }, [location]);

  useEffect(() => {
    initAnalytics(publisherMetaData.integration.googleAnalyticsMeasurementId);
  }, [showCookieOverlay, initAnalytics, publisherMetaData]);

  // prevent back-forward cache
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    return () => {
      window.onpageshow = null;
    };
  }, []);

  // Add boot vars to CSS VARS
  useEffect(() => {
    const generalTheme = publisherMetaData.storeTheme.general;
    const buttonTextColor = generalTheme.buttonTextColor;
    const root = document.documentElement;
    root.style.setProperty('--button-text-color', buttonTextColor);
  }, [publisherMetaData.storeTheme.general, publisherMetaData.storeTheme.storeScreen]);

  useEffect(() => {
    const checkURLforProofKey = () => {
      const proofKeyParam = urlParams.get('proofKey');
      const tokenKeyParam = urlParams.get('token');

      if (proofKeyParam && publisherMetaData) {
        setProofKey(proofKeyParam);
        // setMode(ELoginMode.OTP);
      }
      if (tokenKeyParam && publisherMetaData) {
        setOtpToken(tokenKeyParam);
      }
    };

    setIsProductMadness(
      [EBundlesInternalViewModel.JACKPOT_SLOTS, EBundlesInternalViewModel.BIG_FISH_CASINO].includes(
        publisherMetaData.storeTheme.general.bundlesInternalViewModel
      )
    );

    checkURLforProofKey();
  }, [publisherMetaData]);

  useEffect(() => {
    if (isProductMadness && location.pathname.includes(ELoginMethods.OTP)) {
      setIsOtpProductMadness(true);
    } else {
      setIsOtpProductMadness(false);
    }

    if (isProductMadness) {
      setLoginBgColor(
        publisherMetaData.storeTheme.general.bundlesInternalViewModel ===
          EBundlesInternalViewModel.JACKPOT_SLOTS
          ? '#220E3C'
          : '#060D3B'
      );
    }
  }, [isProductMadness, location.pathname]);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) {
      sessionStorage.setItem('source', 'home screen bookmark');
    }
  }, []);

  useEffect(() => {
    return function cleanup() {
      clear();
    };
  }, []);

  const handleLoginFromCampaign = async (response: any) => {
    API.login.mutate(
      {
        publisherId: response.publisherId,
        token: response.userToken,
        userToken: response.playerId,
        authMethod: ELoginMethods.TOKEN,
      },
      {
        onSuccess: async (data: { data: LoginResponse }) => {
          localStorageUtil.set(ELocalStorageKeys.SESSION_TOKEN, data.data.sessionToken);
          localStorageUtil.set(ELocalStorageKeys.PLAYER_DATA, {
            playerId: data.data.playerId,
            playerName: data.data.playerName,
            playerCountry: data.data.playerCountry,
            profileImageUrl: data.data.profileImageUrl,
          });
          localStorageUtil.set(ELocalStorageKeys.CAMPAIGN_OFFER_ID, data.data.offerId);
          navigate(
            `/shop${isCampaignPath ? `?from=campaign` : ''}&login_redirect=true&token=${response.token}`
          );
        },
      }
    );
  };

  const loginButtonClickWrapper = async (callback: Function, method: ELoginMethods) => {
    if (method !== ('apple' as ELoginMethods.APPLE)) setProcessing(true);
    customEvents.sendCustomEvent(
      EEventsType.LOGIN_CLICKED,
      loginPageService.getLoginEventsData({
        eventName: EEventsType.LOGIN_CLICKED,
        method,
        gameName: publisherMetaData.publisher?.storeTabName,
      }),
      EStorePhase.PRE_LOGIN
    );
    sendPublisherWebhookLoginEvent(ELoginEvents.LOGIN_METHOD_SELECTED, method);
    localStorageUtil.set(ESessionStorageKeys.REDIRECT_STATE, `${method}redirect`);
    callback();
  };

  if (loggingWithProofKey) {
    return (
      <Overlay overlayPercentage={80} zIndex={10000}>
        <AcCircularLoader text={t('processing')} />
      </Overlay>
    );
  }

  const loginProps = {
    isMobilePreview: false,
    playerAuthData: publisherMetaData.integration.playersAuthentication,
    loginLogoSize: publisherMetaData.storeTheme.login.loginLogoSize,
    otpButton: publisherMetaData.storeTheme.login.otpButton,
    getOtp: API.getOTP,
    text: publisherMetaData.storeTheme.login.text,
    textColor: publisherMetaData.storeTheme.login.textColor,
    textSize: publisherMetaData.storeTheme.login.textSize,
    textWeight: publisherMetaData.storeTheme.login.textWeight,
    logo:
      publisherMetaData.storeTheme.login.loginLogoImage ||
      publisherMetaData.storeTheme.general.logo,
    loginButtonClickWrapper: loginButtonClickWrapper,
    fontFamily:
      publisherMetaData.storeTheme.login.font || publisherMetaData.storeTheme.general.font,
    termsAndConditionsLink: publisherMetaData.publisher.termsAndConditionsUrl,
    privacyPolicyLink: publisherMetaData.publisher.privacyPolicyUrl,
    otpTokenKey: otpToken,
    flex: 1,
    gameName: publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel,
    openLinksInNewTab: publisherMetaData?.storeTheme?.login?.openLinksInNewTab,
    translations: {
      signInWithFacebook: t('login.signInWithFacebook'),
      signInWithGoogle: t('login.signInWithGoogle'),
      signInWithApple: t('login.signInWithApple'),
      loginWithPlayerId: t('login.loginWithPlayerId'),
      loginWithUsername: t('login.loginWithUserName'),
      userName: t('login.userName'),
      password: t('login.password'),
      login: t('login.login'),
      contactSupport: t('login.contactSupport'),
      privacyPolicy: t('login.privacyPolicy'),
      termsAndConditions: t('login.termsAndConditions'),
      byLoggingIn: t('login.byLoggingIn'),
      and: t('login.and'),
      back: t('login.back'),
      playerId: t('login.playerId'),
      leavingWebviewTitle: t('login.leavingWebviewTitle'),
      leavingWebviewDescription: t('login.leavingWebviewDescription'),
      leavingWebviewButton: t('login.leavingWebviewButton'),
      or: t('login.or'),
    },
  };

  return (
    <div>
      {(processing || proofKey || isCampaignPath) && (
        <Overlay overlayPercentage={80} zIndex={10000}>
          <AcCircularLoader text={t('processing')} />
        </Overlay>
      )}
      <Background
        height={isProductMadness && (isDesktop || isLandscape) ? '100vh' : '100%'}
        width={isProductMadness && (isDesktop || isLandscape) ? '100%' : '100vw'}
        backgroundImageMobile={
          publisherMetaData.storeTheme.login.loginBackgroundImageMobile ||
          publisherMetaData.storeTheme.general.backgroundImageMobile
        }
        backgroundImageDesktop={
          (otpProductMadness
            ? publisherMetaData.storeTheme.general.backgroundImageDesktop
            : publisherMetaData.storeTheme.login.loginBackgroundImageDesktop) ||
          publisherMetaData.storeTheme.general.backgroundImageDesktop
        }
        backgroundPosition={isProductMadness && isDesktop ? 'center bottom' : 'center top'}
        backgroundSize={
          isProductMadness && !otpProductMadness && !isDesktop && !isLandscape ? 'contain' : 'cover'
        }
        backgroundColor={loginBgColor}
      >
        {!isCampaignPath ? (
          <Stack
            display={isProductMadness && (isDesktop || isLandscape) ? 'block' : 'flex'}
            justifyContent={isMobile ? 'flex-start' : 'center'}
            alignItems="center"
            height="100%"
            minHeight="100vh"
          >
            <Routes>
              <Route
                path="/tokenId"
                element={<Login {...loginProps} mode={'tokenId' as ELoginMode} />}
              />
              <Route
                path="/username"
                element={<Login {...loginProps} mode={'username' as ELoginMode} />}
              />
              <Route path="/otp" element={<Login {...loginProps} mode={'otp' as ELoginMode} />} />
              <Route
                path="/*"
                element={<Login {...loginProps} mode={'providers' as ELoginMode} />}
              />
            </Routes>
            {shouldDisplayLanguagePicker && (
              <Stack pb={2}>
                <LanguagePicker />
              </Stack>
            )}
          </Stack>
        ) : (
          <Stack width="100%" height="100vh" justifyContent="center" alignItems="center"></Stack>
        )}
      </Background>
      {showCookieOverlay && <CookieConsent setShowCookieOverlay={setShowCookieOverlay} />}
      {showInfoModal && iframeUrl && (
        <InfoModal closeModal={closeInfoModal} iframeUrl={iframeUrl} />
      )}
    </div>
  );
};
