import React from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { GoogleLoginProps } from '../types';
import { ELoginEvents, ELoginMethods, ESessionStorageKeys } from '../../../constants/enums';
import { LoginButton } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import useLoginEvents from '../../../hooks/useLoginEvents';
import { localStorageUtil } from 'state/localStorage.state.service';
import { useLoginState } from 'state/hooks/login.state.hook';

export const GoogleLogin: React.FC<GoogleLoginProps> = ({ loginButtonClickWrapper }) => {
  const { loginResponse, setProcessing } = useLoginState();

  const { sendPublisherWebhookLoginEvent } = useLoginEvents();

  const { t } = useTranslation();
  const handleNonOAuthError = async () => {
    setProcessing(false);
    sendPublisherWebhookLoginEvent(ELoginEvents.LOGIN_CANCELED, ELoginMethods.GOOGLE);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => googleResponseHandler(tokenResponse),
    onNonOAuthError: handleNonOAuthError,
  });

  const googleResponseHandler = (response: TokenResponse) => {
    if (!response.access_token) return;

    const data = {
      userToken: response.access_token,
      authMethod: ELoginMethods.GOOGLE,
    };
    localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
    loginResponse(data);
  };

  return (
    <LoginButton
      variant="google"
      callback={() => loginButtonClickWrapper(googleLogin, ELoginMethods.GOOGLE)}
      text={t('login.signInWithGoogle')}
    >
      <img src="https://media.appcharge.com/defaults/google_icon.svg" alt="google icon" />
    </LoginButton>
  );
};
