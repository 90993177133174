import { HeartOfVegasPlayerLevel } from '@appcharge/shared-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { EBundlesInternalViewModel } from '../../../constants/enums';
import { BootResponse } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';

export interface IPlayerLevelWrapperProps {
  viewModel: EBundlesInternalViewModel;
  assetUrl: string;
  text: string;
  endsIn: number;
  refetchHandler: () => void;
}

const mapPlayerLevelToTemplate: Partial<Record<EBundlesInternalViewModel, any>> = {
  [EBundlesInternalViewModel.HEART_OF_VEGAS]: HeartOfVegasPlayerLevel,
};

export const PlayerLevelWrapper: React.FC = () => {
  const [playerLevel, setPlayerLevel] = useState<any>(null);

  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;

  useEffect(() => {
    if (!API.getOffersV2.data?.offers || !API.getOffersV2.data?.playerLevel) return;

    setPlayerLevel(API.getOffersV2.data.playerLevel);
  }, [API.getOffersV2.data?.offers, API.getOffersV2.data?.playerLevel]);

  const refetchHandler = () => {
    API.getOffersV2.refetch();
  };

  const PlayerLevel = useMemo(
    () => mapPlayerLevelToTemplate[bundlesInternalViewModel],
    [bundlesInternalViewModel]
  );

  if (!PlayerLevel || !playerLevel) return <></>;

  return (
    <PlayerLevel
      imageSrc={playerLevel.assetUrl}
      text={playerLevel.text}
      endsIn={playerLevel.endsIn}
      onTimerDoneHandler={refetchHandler}
    />
  );
};
