import { useEffect } from 'react';
import useCustomEvents from './useCustomEvents';
import { EEventsType, EStorePhase } from '../constants/enums';
import { OfferData } from '../constants/apiResponses.types';
import { useEventBatchContext } from './useEventBatch';
import { ProductData } from '@appcharge/shared-ui';

const observedItems = [] as string[];

export default function useObserver(
  element: HTMLElement | null,
  data: OfferData,
  offerIndex: number
) {
  const customEvents = useCustomEvents();
  const { sendEvent } = useEventBatchContext();

  useEffect(() => {
    if (!element) return;
    const options = {
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entry, observer) => {
      if (!entry[0].isIntersecting) return;
      if (!observedItems.includes(data.offerId)) {
        observedItems.push(data.offerId);
        customEvents.sendCustomEvent(
          EEventsType.OFFERS_SHOWN,
          {
            offer_id: data.offerId,
            external_id: data.publisherOfferId,
            name: data.offerName,
            sale_percentage: data.dynamicOfferUi?.salePercentage,
            type: data.offerType,
            sub_type: data?.subType,
            start_date: data.schedule?.timeFrames[0]?.startTime,
            end_date: data.schedule?.timeFrames[0]?.endTime,
            offerIndex,
            products: data.productsSequence[data.indexToCollect || 0].products.map(
              (product: ProductData) => ({
                product_id: product.publisherProductId,
                product_name: product.name,
                product_type: product.productType,
                product_quantity: product.quantity,
              })
            ),
            priceInUsdCents: data.productsSequence[data.indexToCollect || 0].priceInUsdCents,
          },
          EStorePhase.POST_LOGIN
        );
        sendEvent(data);
      }
      observer.disconnect();
    }, options);
    observer.observe(element);
  }, [element]);
}
