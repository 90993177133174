export enum ETheme {
  LIGHT,
  DARK,
}

export const themes = {
  [ETheme.LIGHT]: {
    typography: {
      htmlFontSize: 16,
      fontFamily: 'Montserrat',
      h1: {
        color: '#ffe060',
        fontSize: '2.6em',
        '@media (min-width:768px)': {
          fontSize: '4em',
        },
      },
      h2: {
        color: '#ffffff',
        fontSize: '1.3em',
        letterSpacing: '0.05em',
        '@media (min-width:1024px)': {
          fontSize: '1.8rem',
        },
      },
      h3: {
        fontSize: '1.5em',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      h4: {
        fontSize: '1.2em',
        fontWeight: '100',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      h5: {
        color: '#8f5a34',
        fontSize: '1.3em',
      },
      caption: {
        color: '#ffffff',
        fontSize: '0.625rem',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      subtitle1: {
        color: '#ffffff',
        fontSize: '1rem',
        lineHeight: '1.2em',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      subtitle2: {
        color: '#ffffff',
        fontSize: '1rem',
        lineHeight: '1.2em',
      },
      body1: {
        color: '#000000',
        fontSize: '.8em',
        lineHeight: '1.2em',
        fontWeight: '100',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      body2: {
        color: '#ffffff',
        fontSize: '0.7em',
        letterSpacing: '0.02em',
      },
      button: {
        fontSize: '1.4em',
        fontWeight: 700,
        color: '#003806',
      },
    },
  },
};
