import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import resources from './locales/translations';
import { SupportedLanguage } from './constants/apiRequest.types';
import { localStorageUtil } from 'state/localStorage.state.service';
import { ELocalStorageKeys } from './constants/enums';
import { DEFAULT_SYSTEM_LANGUAGE } from './constants/defaults';
import { localizationStateService } from 'state/localization.state.service';

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_SYSTEM_LANGUAGE,
  lng: DEFAULT_SYSTEM_LANGUAGE,
  resources,
  interpolation: {
    escapeValue: false,
  },
  ns: ['common'],
  defaultNS: 'common',
  preload: [DEFAULT_SYSTEM_LANGUAGE],
  supportedLngs: [DEFAULT_SYSTEM_LANGUAGE],
});

const fetchSupportedLanguages = async (
  getLanguagesList: (publisherId: string) => Promise<SupportedLanguage[]>,
  publisherId: string
) => {
  let supportedLanguagesList = [DEFAULT_SYSTEM_LANGUAGE];
  try {
    const languagesList = await getLanguagesList(publisherId);
    localizationStateService.setLanguagesList(languagesList);
    const existingLanguages = new Set<string>();

    const filteredLanguagesList = languagesList.filter(
      (item) => item.language !== DEFAULT_SYSTEM_LANGUAGE
    );

    if (filteredLanguagesList.length === 0) {
      return supportedLanguagesList;
    }

    supportedLanguagesList = supportedLanguagesList.concat(
      filteredLanguagesList
        .filter(
          (item: SupportedLanguage) =>
            !existingLanguages.has(item.language) && existingLanguages.add(item.language)
        )
        .map((item: SupportedLanguage) => item.language)
    );

    return supportedLanguagesList;
  } catch (error) {
    console.error('Error fetching supported languages:', error);
    return [DEFAULT_SYSTEM_LANGUAGE];
  }
};

const initI18n = async ({
  getLanguagesList,
  publisherId,
  language,
}: {
  getLanguagesList: (publisherId: string) => Promise<SupportedLanguage[]>;
  publisherId: string;
  language: string;
}) => {
  try {
    localizationStateService.setIsI18nReady(false);
    const initialLanguage = localStorageUtil.get(ELocalStorageKeys.CURRENT_LANGUAGE) || language;
    const supportedLanguages = await fetchSupportedLanguages(getLanguagesList, publisherId);
    const lngToSetAsCurrent = supportedLanguages.includes(initialLanguage)
      ? initialLanguage
      : DEFAULT_SYSTEM_LANGUAGE;

    localizationStateService.setCurrentLanguage(lngToSetAsCurrent);

    if (localStorageUtil.get(ELocalStorageKeys.CURRENT_LANGUAGE) !== lngToSetAsCurrent) {
      localStorageUtil.set(ELocalStorageKeys.CURRENT_LANGUAGE, lngToSetAsCurrent);
    }

    await i18n.use(Backend).init({
      ...i18n.options,
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            prefix: 'i18next_res_',
            expirationTime: 7 * 24 * 60 * 60 * 1000,
          },
          {
            loadPath: `${process.env.REACT_APP_API_BASE_URL}/store/translations/${publisherId}/languages/{{lng}}`,
          },
        ],
      },
      supportedLngs: supportedLanguages,
      lng: lngToSetAsCurrent,
      fallbackLng: DEFAULT_SYSTEM_LANGUAGE,
      debug: process.env.NODE_ENV !== 'production',
    });

    localizationStateService.setIsI18nReady(true);

    i18n.emit('supportedLanguagesChanged', supportedLanguages);

    if (lngToSetAsCurrent !== DEFAULT_SYSTEM_LANGUAGE) {
      await changeLanguage(lngToSetAsCurrent);
    }
  } catch (error) {
    localizationStateService.setIsI18nReady(true);

    console.error('Error during i18n initialization:', error);
  }
};

const changeLanguage = async (language: string) => {
  try {
    await i18n.reloadResources(language);
    await i18n.changeLanguage(language);
    const parsedLngName = language === 'en' ? DEFAULT_SYSTEM_LANGUAGE : language;
    localizationStateService.setCurrentLanguage(parsedLngName);
    localStorageUtil.set(ELocalStorageKeys.CURRENT_LANGUAGE, parsedLngName);
  } catch (error) {
    console.error(`Error changing language to ${language}:`, error);
    if (language !== DEFAULT_SYSTEM_LANGUAGE) {
      await i18n.changeLanguage(DEFAULT_SYSTEM_LANGUAGE);
    }
  }
};

export { i18n, initI18n, changeLanguage };
