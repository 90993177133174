import { LoginButton, LoginInput } from '@appcharge/shared-ui';
import { ELoginMethods } from '../../../constants/enums';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';

export const UsernamePasswordMode: React.FC = () => {
  const { t } = useTranslation();
  const { isWebview } = useLoginPage();
  const { loginResponse } = useLoginState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isDisabled = !username.trim().length || !password.trim().length;

  if (isWebview) return <></>;

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isDisabled) return;
    loginResponse({
      authMethod: ELoginMethods.USERNAME,
      userName: username,
      userPassword: password,
    });
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
      <LoginInput
        type="text"
        placeholder={t('login.userName')}
        value={username}
        setValue={setUsername}
      />
      <LoginInput
        type="password"
        placeholder={t('login.password')}
        value={password}
        setValue={setPassword}
      />
      <LoginButton type="submit" variant="white" text={t('login.login')} isDisabled={isDisabled} />
    </form>
  );
};
