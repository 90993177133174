import { Component, ErrorInfo, ReactNode } from 'react';
import mixpanel, { Dict } from 'mixpanel-browser';
import { BootResponse } from 'constants/apiResponses.types';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  publisherMetaData: BootResponse;

  constructor(props: Props) {
    super(props);
    this.publisherMetaData = localStorage.getItem('PUBLISHER_META')
      ? JSON.parse(localStorage.getItem('PUBLISHER_META')!)
      : null;
  }

  sendCustomEvent = (eventType: string, options: Dict, eventPhase: string = 'UNKNOWN') => {
    // Commented out checks, consider implementing proper checks if needed
    // if (!localStorage.getItem('ANALYTICS')) return;
    // if (!this.publisherMetaData?.externalServicesConfig?.mixpanelActive) {
    //   return;
    // }
    const playerData = localStorage.getItem('PLAYER_DATA')
      ? JSON.parse(localStorage.getItem('PLAYER_DATA')!)
      : null;
    const platform = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? 'mobile'
      : 'desktop';

    options.environment = process.env.REACT_APP_ENV || 'local';
    options.publisher_id = this.publisherMetaData?.storeTheme?.publisherId;
    options.URL = window.location.origin;
    options.platform = platform;
    options.language = navigator.language;
    options.store_version = process.env.REACT_APP_STORE_VERSION;
    options.shared_ui_version = process.env.REACT_APP_SHARED_UI_VERSION;
    options.checkout_sdk_version = process.env.REACT_APP_CHECKOUT_SDK_VERSION;

    if (eventPhase === 'POST_LOGIN') {
      if (playerData) {
        options.player_id = playerData.playerId;
        options.player_country = playerData.playerCountry;
      }
      const sessionData = localStorage.getItem('SESSION_DATA')
        ? JSON.parse(localStorage.getItem('SESSION_DATA')!)
        : null;
      if (sessionData?.id) {
        options.session_id = sessionData.id;
      }
    }
    mixpanel.track(eventType, options);
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    console.error('ErrorBoundary error:', error);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.sendCustomEvent('CLIENT_CRASH_ERROR', { error: error.message }, 'UNKNOWN');
    console.error('Uncaught error:', error, errorInfo);
    // You can also log the error to an error reporting service here
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
      // <Stack width="100%" height="100vh" justifyContent="center" alignItems="center">
      //   <Typography>{'Something went wrong, please try again later.'}</Typography>
      // </Stack>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
