import { InfoModalProps } from './InfoModal.types';
import './style.scss';
import { useTranslation } from 'react-i18next';

const InfoModal = ({ closeModal, iframeUrl }: InfoModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="transparentOverlay">
      <div className="infoModal">
        <span onClick={closeModal}>X</span>
        <iframe
          title={t('infoModal.legalInfo')}
          src={iframeUrl}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};

export default InfoModal;
