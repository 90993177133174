import { Observable } from 'state/observable/observable.state.service';

export class LoginStateService {
  readonly processing = new Observable<boolean>(false);
  readonly loggingWithProofKey = new Observable<boolean>(false);
  readonly proofKey = new Observable<string>('');
  readonly iframeUrl = new Observable<string>('');
  readonly showInfoModal = new Observable<boolean>(false);

  clear = () => {
    this.processing.set(false);
    this.loggingWithProofKey.set(false);
    this.showInfoModal.set(false);
    this.proofKey.set('');
    this.iframeUrl.set('');
  };
  setProcessing = (isProcessing: boolean) => this.processing.set(isProcessing);
  setLoggingWithProofKey = (isLogginWithProofkey: boolean) =>
    this.loggingWithProofKey.set(isLogginWithProofkey);
  setProofKey = (key: string) => this.proofKey.set(key);
  setIframeUrl = (url: string) => this.iframeUrl.set(url);
  setShowInfoModal = (show: boolean) => this.showInfoModal.set(show);
}
export const loginStateService = new LoginStateService();
