import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Observable } from './observable.state.service';

// Based on https://blog.betomorrow.com/replacing-redux-with-observables-and-react-hooks-acdbbaf5ba80
export const useObservable = <T>(observable: Observable<T>): T | undefined => {
  const [val, setVal] = useState(observable.get());

  useEffect(() => {
    // This makes sure we have the latest value in the state, and WILL NOT rerender if the value is the same as before.
    if (!isEqual(observable.get(), val)) {
      setVal(observable.get());
    }

    return observable.subscribe(setVal);
  }, [observable]);

  return val;
};
