import MessagePage from '../../components/message-page/message-page';
import { useTranslation } from 'react-i18next';

const Maintenance = () => {
  const { t } = useTranslation();
  const dic = {
    headline: t('maintenance.underConstruction.title'),
    lines: [t('maintenance.underConstruction.description')],
    images: [
      {
        src: `${process.env.PUBLIC_URL}/assets/images/giraff.png`,
        class: 'giraff',
      },
    ],
  };
  return (
    <MessagePage
      headline={dic.headline}
      lines={dic.lines}
      footerLinks={false}
      images={dic.images}
      returnButton={true}
    />
  );
};

export default Maintenance;
