import { browserName, isMobile, osName } from 'react-device-detect';
import { BootResponse } from '../constants/apiResponses.types';
import { EPublisherWebhookEventType, ELoginMethods, ELoginEvents } from '../constants/enums';
import { loginPageService } from '../pages/login/login-page.service';
import useApi from './useApi';

export default function useLoginEvents() {
  const API = useApi({
    platform: isMobile ? osName : browserName,
  });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  const sendPublisherWebhookLoginEvent = async (
    eventName: ELoginEvents,
    method?: ELoginMethods,
    options?: Record<string, string>
  ) => {
    const loginData = loginPageService.getLoginEventsData({
      eventName,
      gameName: publisherMetaData.publisher.storeTabName,
      method,
      options,
    });
    try {
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.LOGIN,
        data: loginData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    sendPublisherWebhookLoginEvent,
  };
}
