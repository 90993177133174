import { useEffect, useState } from 'react';
import { BootResponse } from '../constants/apiResponses.types';
import { isAndroid, isIOS } from 'react-device-detect';

interface InstallPromptEvent extends Event {
  prompt: () => Promise<void>;
}

const useSaveToHomePage = (publisherMetaData: BootResponse) => {
  const [isSaveToHomePageOpen, setIsSaveToHomePageOpen] = useState(false);
  const [isDisplayAddToHomePageButton, setIsDisplayAddToHomePageButton] = useState(false);
  const [installPrompt, setInstallPrompt] = useState<InstallPromptEvent | null>(null);
  const [isAndroidFallBack, setIsAndroidFallBack] = useState(false);

  const isAddToHomePageVisible =
    !!publisherMetaData.featureFlags?.store_add_to_home_screen_notification &&
    (isIOS || isAndroid) &&
    !(sessionStorage.getItem('source') === 'Home Screen Bookmark');

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      console.log('beforeinstallprompt event triggered');
      setInstallPrompt(e);
      sessionStorage.setItem('installPromptFired', 'true');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const handleAppInstalled = () => {
      setIsDisplayAddToHomePageButton(false);
      sessionStorage.setItem('source', 'home screen bookmark');
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleInstallApp = async () => {
    if (installPrompt) {
      try {
        await installPrompt.prompt();
        setInstallPrompt(null);
        sessionStorage.removeItem('installPromptFired');
      } catch (error) {
        console.error('Install prompt failed', error);
      }
    } else {
      setIsAndroidFallBack(true);
      console.log('Install prompt not available');
    }
  };

  useEffect(() => {
    setIsDisplayAddToHomePageButton(isAddToHomePageVisible);
  }, [publisherMetaData]);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) {
      setIsDisplayAddToHomePageButton(false);
      setIsSaveToHomePageOpen(false);
      sessionStorage.setItem('source', 'home screen bookmark');
    } else if (sessionStorage.getItem('installPromptFired')) {
      // Handle case where beforeinstallprompt already fired but page reloaded
      console.log('Restoring install prompt state');
      setIsDisplayAddToHomePageButton(true);
    }
  }, []);

  const handleCloseSaveToHomePage = () => {
    setIsSaveToHomePageOpen(false);
    setIsAndroidFallBack(false);
  };

  return {
    isSaveToHomePageOpen,
    setIsSaveToHomePageOpen,
    isDisplayAddToHomePageButton,
    handleCloseSaveToHomePage,
    handleInstallApp,
    isAndroidFallBack,
  };
};

export default useSaveToHomePage;
