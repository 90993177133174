import React, { createContext, ReactNode, useContext } from 'react';

declare global {
  interface Window {
    NREUM?: string;
  }
}

interface NewRelicContextType {
  newRelicBrowserAgent: any | null;
}

const NewRelicContext = createContext<NewRelicContextType>({
  newRelicBrowserAgent: null,
});

export const NewRelicProvider = ({ children }: { children: ReactNode }) => {
  const newRelicBrowserAgent = (window as Window).NREUM || null;
  return (
    <NewRelicContext.Provider value={{ newRelicBrowserAgent }}>{children}</NewRelicContext.Provider>
  );
};

export const useNewRelic = () => useContext(NewRelicContext);
