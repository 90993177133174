import { Box } from '@mui/material';
import useObserver from '../../hooks/useObserver';
import { useRef } from 'react';
import { OfferData } from '../../constants/apiResponses.types';

const CarouselItem = ({
  children,
  data,
  index,
}: {
  children: React.ReactNode;
  data: OfferData;
  index: number;
}) => {
  const itemRef = useRef<HTMLDivElement>(null);
  useObserver(itemRef.current, data, index);
  return (
    <Box className="carousel-item" ref={itemRef}>
      {children}
    </Box>
  );
};

export default CarouselItem;
