import { localizationStateService } from 'state/localization.state.service';
import { useObservable } from 'state/observable';

export const useLocalizationState = () => {
  return {
    currentLanguage: useObservable(localizationStateService.currentLanguage),
    languagesList: useObservable(localizationStateService.languagesList),
    isI18nReady: useObservable(localizationStateService.isI18nReady),
    setCurrentLanguage: localizationStateService.setCurrentLanguage,
    setLanguagesList: localizationStateService.setLanguagesList,
  };
};
