import { useContext } from 'react';
import { EventBatchContext } from '../components/EventsBatchProvider/EventBatchProvider';

export const useEventBatchContext = () => {
  const context = useContext(EventBatchContext);
  if (!context) {
    throw new Error('useEventContext must be used within an EventProvider');
  }
  return context;
};
