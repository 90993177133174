export enum EOrderStatus {
  CREATED = 'created',
  REQUEST_FAILED = 'API request failed',
  PAYMENT_PENDING = 'payment_pending',
  PAYMENT_SUCCEED = 'payment_succeed',
  PAYMENT_FAILED = 'payment_failed',
  CHARGE_PENDING = 'charge_pending',
  CHARGE_SUCCEED = 'charge_succeed',
  CHARGE_FAILED = 'charge_failed',
}
