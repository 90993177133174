import React from 'react';
import { LoginButton } from '@appcharge/shared-ui';
import { ELoginMethods } from '../../../constants/enums';
import { IFacebookLoginProps } from '../types';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';

export const FacebookLogin: React.FC<IFacebookLoginProps> = ({ loginButtonClickWrapper }) => {
  const { publisherMetaData } = useLoginPage();

  const appId = publisherMetaData.integration.playersAuthentication.fbAppId;

  const { t } = useTranslation();

  const loginHandler = async () => {
    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?response_type=token&client_id=${appId}&redirect_uri=${window.location.origin}${window.location.pathname}&state=facebookredirect`;
  };

  return (
    <LoginButton
      variant="facebook"
      callback={() => loginButtonClickWrapper(loginHandler, ELoginMethods.FACEBOOK)}
      text={t('login.signInWithFacebook')}
    >
      <img src="https://media.appcharge.com/defaults/facebook_icon_white.svg" alt="facebook icon" />
    </LoginButton>
  );
};
