import React, { useState } from 'react';
import { ELoginMethods } from '../../../constants/enums';
import { LoginButton, LoginInput } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';

export const LoginIdMode: React.FC = () => {
  const { t } = useTranslation();
  const { loginResponse } = useLoginState();
  const { isWebview } = useLoginPage();
  const [id, setId] = useState('');
  const isDisabled = id.trim().length <= 3;

  if (isWebview) return <></>;

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isDisabled) return;
    loginResponse({
      userToken: id,
      authMethod: ELoginMethods.TOKEN,
    });
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
      <LoginInput type="text" placeholder={t('login.playerId')} value={id} setValue={setId} />
      <LoginButton type="submit" variant="white" text={t('login.login')} isDisabled={isDisabled} />
    </form>
  );
};
