import { useEffect, useState } from 'react';

const useStoreAppearance = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    const listener = (e: any) => {
      setIsDarkMode(e.matches);
    };

    // Use the old `addListener` method for iOS 13 compatibility
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', listener);
    } else if (mediaQueryList.addListener) {
      mediaQueryList.addListener(listener);
    }

    return () => {
      // Use the old `removeListener` method for iOS 13 compatibility
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', listener);
      } else if (mediaQueryList.removeListener) {
        mediaQueryList.removeListener(listener);
      }
    };
  }, []);

  return isDarkMode;
};

export default useStoreAppearance;
