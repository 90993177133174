import { MessageBox } from '@appcharge/shared-ui';
import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BootResponse } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';
import { useOffers } from '../hooks/useOffers.hook';
import '../style.scss';

export const MissingOffersBlock: React.FC = () => {
  const { hasNoOffers } = useOffers();
  const { t } = useTranslation();
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  const {
    noOffersTitleText = t('shop.noOffers'),
    noOffersMessageText = t('shop.noSpecialOffers'),
    bundleBorderColor,
  } = useMemo(
    () => publisherMetaData.storeTheme.storeScreen,
    [publisherMetaData.storeTheme.storeScreen]
  );

  if (!hasNoOffers) return <></>;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width="100%"
      className="no_offers_box"
      padding={'2vh'}
    >
      <MessageBox
        title={noOffersTitleText}
        text={noOffersMessageText}
        fontFamily={publisherMetaData.storeTheme.general.font}
        borderColor={bundleBorderColor.colorOne}
      />
    </Stack>
  );
};
